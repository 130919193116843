import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { Button, Footer, Input, Header, Notification, SEO } from "components";
import goBackIcon from "assets/goBack.svg";

import { selectors } from "state/LoginPage/reducer";
import { isPasswordValid, changePassword } from "state/LoginPage/actions";
import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import * as styles from "./ProfileChangePasswordPage.module.scss";

const ProfileChangePasswordPage = ({
  menu,
  loginState,

  changePassword,
  resetMenuFilters,
  setMenuCollection,
}) => {
  const [notificationTitle, setNotificationTitle] = useState("Error");
  const [notification, showNotification] = useState();
  const [isShowErrors, setIsShowErrors] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken =
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken");

  if (!refreshToken) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsShowErrors(true);

    if (
      isPasswordValid(oldPassword).isValid &&
      isPasswordValid(newPassword).isValid &&
      newPassword === repeatPassword
    ) {
      const isChanged = await changePassword({
        oldPassword,
        newPassword,
      });
      if (isChanged) {
        setNotificationTitle("Info");
        showNotification("Your password was updated successfully!");
      }
    }
  };

  return (
    <div className={styles.container}>
      <SEO pathname='/profile/change-password' />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
        isNeedDescription={true}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          <img
            className={styles.goBackIcon}
            src={goBackIcon}
            alt="<"
            onClick={() => navigate('/profile')}
          />
          Change Password
        </div>
        <form className={styles.form}>
          <div className={styles.inputContainer}>
            <div className={styles.label}>Old Password</div>
            <Input
              placeholder="Old Password"
              onChange={setOldPassword}
              isValid={isPasswordValid(oldPassword).isValid}
              isShowErrors={isShowErrors}
              errorMessage="Please input your Old Password"
              inputType="password"
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.label}>New Password</div>
            <Input
              placeholder="New Password"
              onChange={setNewPassword}
              isValid={isPasswordValid(newPassword).isValid}
              isShowErrors={isShowErrors}
              errorMessage="Please input your New Password"
              inputType="password"
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.label}>Confirm New Password</div>
            <Input
              placeholder="New Password"
              onChange={setRepeatPassword}
              isValid={repeatPassword === newPassword ? true : false}
              isShowErrors={isShowErrors}
              errorMessage="Please confirm your New Password"
              inputType="password"
            />
          </div>
        </form>
        <div className={styles.warningContainer}>
          Password must contain at least 6 symbols: one upper case letter, one number, and one special character.
        </div>
        <div className={styles.button}>
          <Button
            value="Save"
            loading={loginState.UIState.isLoading}
            className="buttonSmall"
            customAction={handleSubmit}
          />
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
        <Notification
          title={notificationTitle}
          message={notification}
          onNotificationClosed={() => {
            setNotificationTitle("Error");
            showNotification("");
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
  loginState: selectors.selectLoginState(state),
});

const mapDispatchToProps = {
  changePassword,
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileChangePasswordPage);
