// extracted by mini-css-extract-plugin
export var container = "ProfileChangePasswordPage-module--container--362UB";
export var content = "ProfileChangePasswordPage-module--content--x_-J_";
export var warningContainer = "ProfileChangePasswordPage-module--warningContainer--2JkDq";
export var title = "ProfileChangePasswordPage-module--title--2T6Fk";
export var goBackIcon = "ProfileChangePasswordPage-module--goBackIcon--37SuG";
export var form = "ProfileChangePasswordPage-module--form--3kP7c";
export var inputContainer = "ProfileChangePasswordPage-module--inputContainer--3_OCP";
export var label = "ProfileChangePasswordPage-module--label--XLQF9";
export var button = "ProfileChangePasswordPage-module--button--2hIyF";
export var footerContainer = "ProfileChangePasswordPage-module--footerContainer--2zTKX";